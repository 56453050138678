<template>
  <div class=" mt-2">
    <b-card class="mb-0">
      <template #header>
        <b-col
          cols="12"
          xl="12"
          md="12"
          sm="12"
          class="d-flex"
          style="align-items: center;"
        >
          <b-col
            cols="12"
            xl="8"
            md="8"
            lg="8"
            sm="12"
            class=" d-flex justify-content-start"
            style="align-items: center;"
          >
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            size="sm"
            style="align-items:center;"
            @click="back()"

          >
            <feather-icon
              icon="ArrowLeftCircleIcon"
              class="mr-50"
              size="14"
            />
      <span class="align-middle" >{{ $t('general_buttons.back') }}</span>
    </b-button>
            <h4 class="mb-0 ml-2">
              {{ $t("journals.titles.selected_account_name") }}
              <b>{{ accountName }}</b> {{ $t("journals.titles.names") }}
            </h4>
          </b-col>
          <b-col
            cols="12"
            xl="4"
            lg="4"
            md="4"
            sm="12"
            class=" d-flex justify-content-end pr-0"
          >
            <b-col cols="12" xl="4" lg="4" md="4" sm="12" class="p-0">
              <b-button
                id="info-legend-button"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="flat-primary"
                size="sm"
                class="top-btns animate__animated animate__fadeIn"
              >
                <feather-icon
                  icon="EyeIcon"
                  size="14"
                  style="margin-bottom: 0.3rem;"
                />

                <span class="">{{ $t("journals.buttons.legend_table") }}</span>
              </b-button>
              <b-popover
                target="info-legend-button"
                placement="right"
                triggers="click blur"
              >
                <template #title>
                  {{ $t("journals.titles.legend") }}
                </template>
                <b-table-simple hover small caption-top responsive bordered>
                  <b-tbody>
                    <b-tr @click="changeReverseInitiatedRowColor()">
                      <td style="width: 60%;">
                        {{ $t("journals.titles.reverse_initiated") }}
                      </td>
                      <td :style="{ 'background-color': reverseInitiateRowcolor  }"></td>
                    </b-tr>
                    <b-tr @click="changeReversedRowColor()">
                      <td>
                        {{ $t("journals.titles.reversed") }}
                      </td>
                      <td  :style="{ 'background-color': reversedRowColor  }"></td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-popover>
            </b-col>
          </b-col>
        </b-col>
      </template>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <hr />
        <b-col cols="12" md="3" class="mb-0">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1 mb-1"
                :placeholder="$t('general_text.search_table')"
              />
            </b-col>
        <b-table
          ref="refJournalsAccountNamesTable"
          class="compact_table"
          :items="allAccountNameData"
          :filter="filter"
          filter-debounce="250"
          @filtered="onFiltered"
          :filter-included-fields="filterOn"
          selectable
          select-mode="single"
          responsive
          head-variant="dark"
          :fields="tableColumns"
          show-empty
          :empty-text="$t('journals.messages.no_matching_journals_names')"
          :current-page="currentPage"
          :per-page="perPage"
          caption-top
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortByDesc"
          :tbody-tr-class="legendClass"
          style="max-height: 95vh !important"
            :small="true"
            thead-class="animate__animated animate__fadeIn"

        >
          <!-- :tbody-tr-class="legendClass" -->

          <template #cell(ID)="data">
            <b
              ><span class="font-weight-bold">
                <strong class="text-primary">#{{ data.value }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TcUti -->
          <template #cell(TcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.TcUti ? data.item.TcUti : "-" }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountName -->
          <template #cell(AccountName)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountName ? data.item.AccountName : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountNumber -->
          <template #cell(AccountNumber)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountNumber ? data.item.AccountNumber : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: AccountCode -->
          <template #cell(AccountCode)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.AccountCode ? data.item.AccountCode : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ValueDate -->
          <template #cell(ValueDate)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ dateFormat(data.item.ValueDate) }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Underlying -->
          <template #cell(Underlying)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Underlying ? data.item.Underlying : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Amount -->
          <template #cell(Amount)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Amount ? formatPrice(data.item.Amount, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Price -->
          <template #cell(Price)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.Price ? formatPrice(data.item.Price, 2) : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: TransactionType -->
          <template #cell(TransactionType)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.TransactionType ? data.item.TransactionType : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: EntryDescription -->
          <template #cell(EntryDescription)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.EntryDescription ? data.item.EntryDescription : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: RelatedTcUti -->
          <template #cell(RelatedTcUti)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.RelatedTcUti ? data.item.RelatedTcUti : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: VerificationUser -->
          <template #cell(VerificationUser)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.VerificationUser ? data.item.VerificationUser : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: Reversed -->
          <template #cell(Reversed)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{ data.item.Reversed == 1 ? true : false }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ReversedBy -->
          <template #cell(ReversedBy)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ReversedBy ? data.item.ReversedBy : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: ReversedDateTime -->
          <template #cell(ReversedDateTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.ReversedDateTime != "1900-01-01 00:00:00"
                    ? dateFormatWithTime(data.item.ReversedDateTime)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>

          <!-- Column: SaveDateTime -->
          <template #cell(SaveDateTime)="data">
            <b
              ><span class="font-weight-bold">
                <strong>{{
                  data.item.SaveDateTime != "1900-01-01 00:00:00"
                    ? dateFormatWithTime(data.item.SaveDateTime)
                    : "-"
                }}</strong>
              </span></b
            >
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    {{ /*row colour modal*/ }}
    <b-modal
      id="modal-prevent-closing"
      v-model="changeRowColorModal"
      :title="$t('journals.titles.select_row_color')"
      size="md"
      centered
      :cancel-title="$t('journals.buttons.cancel')"
      ref="row-color-modal"
      hide-footer
    >
        <b-container class="p-2">
          <!-- <div class="d-block text-center">
            <h3>{{$t('journals.titles.select_row_color')}}</h3>
      </div> -->
      <verte display="widget" model="hex" v-model="dynamicRowColor"></verte>
          
        <b-button class="mt-3" variant="outline-primary" block @click="selectRowColor(willChangeRowColor)">{{$t('journals.buttons.select_colour') }} </b-button>
        </b-container>

      {{/****END*** row colour modal*/ }}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BInputGroup,
  BInputGroupAppend,
  BPopover,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import journalsStoreModule from "../journalsStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, min, max } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import "cleave.js/dist/addons/cleave-phone.us";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import { type } from "os";

import Verte from 'verte';
import 'verte/dist/verte.css';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    BTooltip,
    Verte
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const JOURNAL_APP_STORE_MODULE_NAME = "journal";
    // Register module
    if (!store.hasModule(JOURNAL_APP_STORE_MODULE_NAME))
      store.registerModule(JOURNAL_APP_STORE_MODULE_NAME, journalsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(JOURNAL_APP_STORE_MODULE_NAME))
        store.unregisterModule(JOURNAL_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      allAccountNameData: [],
      accountName: null,
      dynamicRowColor:null,
      willChangeRowColor:null,
      changeRowColorModal:false,
      reverseInitiateRowcolor:'#FFFF99',
      reversedRowColor:"coral",
      filterOn: ["TransactionType", "EntryDescription","Underlying"],
      sortByDesc: true,
      sortBy: "ID",
      tableColumns: [
        {
          key: "ID",
          label: "ID",
          class: "text-center w-0 table-padding",
        },
        {
          key: "TcUti",
          label: this.$t("journals.table.sfc_uti"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "AccountName",
          label: this.$t("journals.table.account_name"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "AccountNumber",
          label: this.$t("journals.table.account_number"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "AccountCode",
          label: this.$t("journals.table.account_code"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "ValueDate",
          label: this.$t("journals.table.value_date"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Underlying",
          label: this.$t("journals.table.underlying_ccy"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Amount",
          label: this.$t("journals.table.amount"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Price",
          label: this.$t("journals.table.cost"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "TransactionType",
          label: this.$t("journals.table.transaction_type"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "EntryDescription",
          label: this.$t("journals.table.description"),
          sortable: false,
          class: "text-center table-padding",
        },

        {
          key: "RelatedTcUti",
          label: this.$t("journals.table.related_sfc_uti"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "VerificationUser",
          label: this.$t("journals.table.operations_user"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "Reversed",
          label: this.$t("journals.table.reversed"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "ReversedBy",
          label: this.$t("journals.table.reversed_by"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "ReversedDateTime",
          label: this.$t("journals.table.reversed_date_time"),
          sortable: false,
          class: "text-center table-padding",
        },
        {
          key: "SaveDateTime",
          label: this.$t("journals.table.save_date_time"),
          sortable: false,
          class: "text-center table-padding",
        },
      ],
      perPage: 15,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [15, 25, 50, 100],
      filter: null,

      loading: false,
      rangeDate: null,
      filterDateStart: null,
      filterDateEnd: null,
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
    };
  },
  computed: {
    ...mapState({
      lastJournalSearchData: (state) => state.runLastJournalSearchData,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    ...mapGetters(["authUser"]),
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  mounted() {
    this.accountName = this.$route.params.accountName;
    this.getselectedAccountNames(this.$route.params.accountName);
  },
  methods: {
    back() {
      router.go(-1);
    },
    // search entries
    getselectedAccountNames(accountName) {
      this.$checkAccessRight("JournalEntries", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;

          store
            .dispatch("journal/getSelectedAccountNames", {
              auth: this.user,
              journalData:  {
                ID: null,
                Uti: null,
                AccountName: this.accountName,
                AccountNumber: null,
                AccountCode: null,
                Currency: null,
                Amount: null,
                Cost: null,
                TransactionType: null,
                Description: null,
                RelatedTradeUti: null,
                TradeOpsUser: null,
                EntryFrom: null,
                EntryTo: null,
                ValueFrom: null,
                ValueTo: null,
                selectopt: "All Entries",
                ValueDate: null,
        } 
              
            })
            .then((res) => {
              this.totalRows = res.data.length;
              this.allAccountNameData = [];
              this.allAccountNameData = res.data;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
              this.loading = false;
            });
        } else {
          this.notAuthToastMessage();
          this.loading = false;
        }
      });
    },

    changeReverseInitiatedRowColor(){
      this.willChangeRowColor = 'Reverse Initiate'
      this.changeRowColorModal= true
    },
    changeReversedRowColor() {
      this.willChangeRowColor = "Reversed"
      this.changeRowColorModal= true
    },
    selectRowColor(type){
      if(type == 'Reverse Initiate') {
        if(document.querySelectorAll('.reversedInitiateColor').length > 0) {
        document.querySelectorAll('.reversedInitiateColor').forEach((el) => {
          el.style.backgroundColor = this.dynamicRowColor
          el.classList.remove("reversedInitiateColors")
        })

        this.reverseInitiateRowcolor = this.dynamicRowColor
      }
        }
      else if(type == 'Reversed') {
        if(document.querySelectorAll('.reversedColor').length > 0) {
        document.querySelectorAll('.reversedColor').forEach((el) => {
      
          el.style.backgroundColor = this.dynamicRowColor
          el.classList.remove("reversedColor")
        
        })

      this.reversedRowColor = this.dynamicRowColor
      }
      }
      
      this.getselectedAccountNames()
      this.changeRowColorModal= false
    },


    legendClass(item, type) {
      const executedRowColor = "executedColor";
      const verifiedRowColor = "verifiedColor";
      const journalCreateRowColor = "journalCreateColor";
      const expiredOrderRowColor = "expiredOrderColor";
      const reversedInitiateRowColor = "reversedInitiateColor";
      const reversedRowColor = "reversedColor";
      const canceledRowColor = "canceledColor";

      if (!item || type !== "row") {
        return;
      }

      if (item.ReversalInitiated == 1 && item.Reversed != true) {
        return reversedInitiateRowColor;
      }
      if (item.Reversed == true) {
        // console.log(reversedInitiateRowColor)
        return reversedRowColor;
      }
      
    },

    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("journals.messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    validDateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.please_add_valid_date"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },

    amendOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.amend_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    verifiedSecurityMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.entry_already_verify"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityCreateMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("journals.messages.security_create_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successVerifiedMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("journals.messages.security_verify_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    successInsertSecurityMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: message,
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("journals.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    infoToastMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Amend Note",
            text: message,
            icon: "BookmarkIcon",
            variant: "primary",
          },
        },
        {
          timeout,
        }
      );
    },
    warningMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.security_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    UtiExistMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityIsinExistMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    securityNotFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.security_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    oldSecurityMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.old_security_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("journals.messages.not_authorized"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    dateFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("DD-MM-YYYY");
      } else {
        return "-";
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text:
              this.$t("journals.messages.required_fill_inputs") + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("journals.messages.you_not_change_account"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },

    clockFormat(val) {
      if (val != undefined && val != null && val != "") {
        return moment(val).format("HH:mm:ss");
      } else {
        return "-";
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY HH:mm:ss");
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("userShow", val);
      },
    },
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes("to")) {
            this.filterDateStart = this.rangeDate.split(" to ")[0];
            this.filterDateEnd = this.rangeDate.split(" to ")[1];

            this.selectedEvents();
          }
        }
      },
    },
  },
};
</script>




<style>
#securityModal .modal-header .modal-title {
  color: white !important;
}
.padding_08 {
  padding: 0.7rem !important;
}

.executedColor {
  background-color: paleturquoise;
}

.verifiedColor{
  background-color: greenyellow ;
}

.journalCreateColor {
  background-color: mediumpurple;
}

.expiredOrderColor {
  background-color: silver;
}

.reversedInitiateColor {
  background-color: #FFFF99;
}
.reversedColor {
  background-color: coral;
}
.canceledColor {
  background-color: rgb(162, 36, 36) !important;
}
</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";

</style>


<style scoped>
.padding_08 {
  padding: 0.7rem !important;
}
/* .table-verified {
  background-color: greenyellow !important;
} */

#table-container {
  display: flex;

  flex-wrap: nowrap;

  overflow-x: auto;
}

#table-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ID_background {
  background-color: gainsboro !important;
}
</style>


<style>
#journalsModal .modal-header .modal-title {
  color: white !important;
}




.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

.b-table-sticky-column:last-child {
  right: 0;
}





.sticky-header {
  position: sticky;
  top: 78px;
  z-index: 999;
  /* Add other styling as needed */
}


.b-table-sticky-header{
  max-height : unset !important;
  height: inherit !important;

}
.hide-rows thead tr[role="row"] { display: none; }


/* width */
::-webkit-scrollbar {
  width: 5px;
  height:5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(77, 73, 73); 
}

</style>
  
<style lang="scss" scoped>
  
   
  
@import "../../../assets/scss/compact-design.scss";

 

// .v-select::v-deep .vs__selected {

//     position: relative !important;

//   }

</style>

